import {numberFormat, timeFormat} from "@custom";
export const tableColumn = [
    {
        type: 'selection',
        width: 55,
        selectable:(row) => {
            // console.log(row)
            return !row.tuiFang;
        },
    },
    {
        label: "序号",
        type: "index",
        width: 50
    },
    {
        prop: "payBillNum",
        label: "账单号",
        width: 160,
    },
    {
        prop: "apartmentName",
        label: "座落",
        width: 250,
    },
    {
        prop: "contractNum",
        label: "合同编号",
        width: 150,
    },
    {
        prop: "leasorName",
        label: "租户姓名",
        // width: 80,
    },
    {
        prop: "leasorTel",
        label: "租户电话",
        width: 100,
        // render: (h, { row }) => {
        //     const {createTime} = row;
        //     return createTime ? timeFormat(new Date(createTime)) : "";
        // }
    },
    {
        prop: "typeCode",
        label: "收费类型",
        render: (h, {row}) => {
            const {typeCode } = row;
            if(typeCode === 500501)   return '电费'
            if(typeCode === 500502)   return '水费'
            if(typeCode === 500503)   return '物业费'
            if(typeCode === 500504)   return '房租'
            if(typeCode === 500505)   return '定金'
            if(typeCode === 500506)   return '充值'
            if(typeCode === 500507)   return '服务费'
            if(typeCode === 500509)   return '其他费用'
            if(typeCode === 500511)   return '换房手续费'
            if(typeCode === 500512)   return '押金'
            if(typeCode === 500513)   return '清洁费'
            if(typeCode === 500514)   return '维修费'
        }
    },
    {
        prop: "payBillPeriod",
        label: "账单周期",
        width: 200,
    },
    {
        prop: "amount",
        label: "账单金额",
        render: (h, {row}) => {
            const {amount } = row;
            return h("span", numberFormat(amount / 100));
        }
        // width: 100,
        // render: (h, { row }) => {
        //     const {amount} = row;
        //     return amount ? timeFormat(new Date(amount)) : "";
        // }
    },
    {
        prop: "channel",
        label: "支付渠道",
    },
    {
        prop: "statusName",
        label: "缴费状态",
    },
    {
        prop: "receivableDate",
        label: "应收款日期",
        // width: 100,
        render: (h, { row }) => {
            const {receivableDate} = row;
            return receivableDate ? timeFormat(new Date(receivableDate),"yyyy-MM-dd") : "";
        }
    },
    {
        prop: "collectionDate",
        label: "收款时间",
        width: 200,
        render: (h, { row }) => {
            const {collectionDate} = row;
            return collectionDate ? timeFormat(new Date(collectionDate),"yyyy-MM-dd HH:mm:ss") : "";
        }
    },
];