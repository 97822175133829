import request from '@http';

// 2023/05/10 王珂 物业管理=》报修管理
export function repairManagement(params) {
    return request({
        method: 'get',
        url: `/api/repair/repairApplyList`,
        params,
    });
}
// 2023/5/11 王珂 报修记录附件
export function repairInfo(params) {
    return request({
        method: 'get',
        url: `/api/repair/repairApplyImageList`,
        params,
    });
}
// 2024-3-20-欠费列表-陈守亮
export function arrearsbillapi(params) {
    return request({
        method: 'get',
        url: `api/gzf/paybill/overdue/list`,
        params,
    });
}
// 2024-3-20-陈守亮-欠费列表导出
export function deriveapi(params) {
    return request({
        method: 'get',
        url: `api/gzf/paybill/overdue/export`,
        params,
        responseType: 'blob',
    });
}
// 2024-3-20-陈守亮-欠费列表更新
export function synchronizationapi(params) {
    return request({
        method: 'get',
        url: `api/gzf/paybill/overdue/renewal`,
        params,
    });
}